import React, { useContext } from "react";
import Model from "../../ui-v2/Modal/Model";
import { AppContext } from '../../App';
import SelectField from "../../ui-v2/SelectField/SelectField";
import { MenuItem } from "@material-ui/core";

export const modelArray = [
    // GPT Models
    { displayName: "GPT-4.5 Preview", model: "gpt-4.5-preview", input: 75.00, cached_input: 37.50, output: 150.00, contextLength: 128000 },
    { displayName: "GPT-4o", model: "gpt-4o", input: 2.50, cached_input: 1.25, output: 10.00, contextLength: 128000 },
    { displayName: "GPT-4o Mini", model: "gpt-4o-mini", input: 0.15, cached_input: 0.075, output: 0.60, contextLength: 128000 },
    { displayName: "o1", model: "o1", input: 15.00, cached_input: 7.50, output: 60.00, contextLength: 200000 },
    { displayName: "o1 Pro", model: "o1-pro", input: 150.00, cached_input: null, output: 600.00, contextLength: 200000 },
    { displayName: "o1 Mini", model: "o1-mini", input: 1.10, cached_input: 0.55, output: 4.40, contextLength: 128000 },
    { displayName: "o3 Mini", model: "o3-mini", input: 1.10, cached_input: 0.55, output: 4.40, contextLength: 200000 },

    // Claude Models
    { displayName: "Claude 3.7 Sonnet", model: "claude-3-7-sonnet-20250219", input: 3.00, cached_input: 1.50, output: 15.00, contextLength: 200000 },
    { displayName: "Claude 3.5 Haiku", model: "claude-3-5-haiku-20241022", input: 0.80, cached_input: 0.40, output: 4.00, contextLength: 200000 },

];

const Main = ({ selectedModel = "gpt-4o-mini", onModelChange }) => {
    const { user } = useContext(AppContext);
    const isAdmin = user?.email === "admin@stockalgos.com";

    // Find the price of gpt-4o for comparison
    const gpt4oPrice = modelArray.find(m => m.model === "gpt-4o")?.input || 0;

    const handleChange = (event) => {
        if (onModelChange) {
            onModelChange(event.target.value);
        }
    };

    return (
        <>
            <SelectField 
                native={true} 
                label="Select AI Model:" 
                className="small"
                value={selectedModel}
                onChange={handleChange}
            >
                {modelArray.map((model) => {
                    const isExpensive = model.input > gpt4oPrice;
                    const isDisabled = isExpensive && !isAdmin;
                    const contextLengthInK = model.contextLength / 1000;
                    return (
                        <option
                            key={model.model}
                            value={model.model}
                            disabled={isDisabled}
                            className="small"
                        >
                            {model.displayName} - Input: ${model.input}/1K tokens, Output: ${model.output}/1K tokens, Context: {contextLengthInK}K
                            {isDisabled && " (Admin Only)"}
                        </option>
                    );
                })}
            </SelectField>
            <div className="text-accent-4">
                <p className="mt-0">Note: Prices are per 1K tokens</p>
                <p>Input: Cost for sending tokens to the model</p>
                <p>Output: Cost for receiving tokens from the model</p>
                <p>Context: Maximum token length the model can process</p>
                {!isAdmin && (
                    <p className="text-red-500 mt-2">
                        Note: Some models are restricted to admin users only
                    </p>
                )}
            </div>
        </>
    );
};

const AiModelsForChat = ({ selectedModel, onModelChange, component }) => {
    return (
        <Model
            title="Select AI Model:"
            component={component}
        >
            <Main
                selectedModel={selectedModel}
                onModelChange={onModelChange}
            />
        </Model>
    );
};

export default AiModelsForChat;